import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Menu from "../components/docs/menu"

import Template from "../templates/post"

import { H3 } from "../styles/global"
import { DocSection } from "../styles/mdDocs"

import ChevLeft from "../assets/svg/left-arrow.svg"

const SectionWrapper = ({ children }) => (
  <section className="mt-20 md:mt-20 md:mb-20 deconta-docs wrapper block md:flex pl-4 pr-4 md:pl-10 md:pr-10">
    {children}
  </section>
)

const DocSectionWrapper = ({ children }) => (
  <SectionStyle className="md:pl-10">{children}</SectionStyle>
)
const SectionStyle = styled.section`
  & .icon {
    fill: var(--textSd) !important;
    top: 8.5px;
  }
`

const DocSectionExplanation = ({ last, children }) => (
  <DocSectionExplanationStyle className={`block ${!last ? "pb-5" : "pb-0"}`}>
    {children}
  </DocSectionExplanationStyle>
)
const DocSectionExplanationStyle = styled.span`
  color: var(--textSd);
  & span {
    color var(--blueLight);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
  & .icon {
    fill: var(--textSd) !important;
  }
`

const Docs = () => {
  return (
    <Layout page={"docs"}>
      <SectionWrapper className="">
        <div className="block md:flex">
          <div className="sidebar w-full pb-5 md:pb-0 md:w-3/12">
            <Menu page="docs" />
          </div>
          <div className="content md:w-9/12">
            <DocSectionWrapper>
              <section>
                <div className="relative pl-6 flex">
                  <button onClick={() => window.history.back()}>
                    <ChevLeft className="h-3 absolute left-0 icon" />
                  </button>
                  <H3 id="started">Getting started</H3>
                </div>

                <DocSection link="definition">
                  <Template postPath="/docs/definitions" />
                </DocSection>

                <DocSection link="api-base">
                  <Template postPath="/docs/base-url" />
                </DocSection>

                <DocSection link="api-login">
                  <Template postPath="/docs/login-name" />
                </DocSection>

                <DocSection link="api-clientId">
                  <Template postPath="/docs/client-id" />
                </DocSection>

                <DocSection link="api-key">
                  <Template postPath="/docs/api-key" />
                </DocSection>

                <DocSection link="api-response">
                  <Template postPath="/docs/api-response" />
                </DocSection>

                <DocSection link="api-endpoints">
                  <Template postPath="/docs/api-endpoints" />
                </DocSection>

                <DocSection link="api-errors">
                  <Template postPath="/docs/potential-errors" />
                </DocSection>
              </section>
            </DocSectionWrapper>
          </div>
        </div>
      </SectionWrapper>
    </Layout>
  )
}

export default Docs

import React from "react"
import styled from "styled-components"

const DocSectionStyle = styled.div`
  background: var(--surface);

  & p {
    color: var(--textSd);
    & a {
      color: var(--blue);
    }
  }

  & .list-link {
    color: var(--blue);
  }

  & .icon {
    fill: var(--textSd) !important;
  }

  & .icon:hover {
    fill: var(--text) !important;
  }

  & h3 {
    color: var(--text);
    font-weight: 500;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    padding-bottom: 1.25rem /* 20px */;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  & h4 {
    color: var(--text);
    font-weight: 500;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    padding-bottom: 1.25rem /* 20px */;
  }

  & h5 {
  }

  & h6 {
    color: var(--text);
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    font-weight: 500;
    padding-bottom: 0.5rem /* 8px */;
    padding-top: 1.25rem /* 20px */;
  }

  & table {
    width: 100%;
    background: var(--surfaceBg);
    background-size: cover;
    border-radius: 0.375rem /* 6px */;

    & thead {
      & tr {
        background: var(--blue);

        & th {
          color: var(--blueText);
          font-weight: 500;
          font-size: 0.875rem /* 14px */;
          line-height: 1.25rem /* 20px */;
          padding-left: 0.25rem /* 4px */;
        }

        & th:first-child {
          border-top-left-radius: 0.375rem /* 6px */;
        }

        & th:last-child {
          border-top-right-radius: 0.375rem /* 6px */;
        }
      }
    }

    & tbody {
      font-size: 0.875rem /* 14px */;
      line-height: 1.25rem /* 20px */;
      & tr {
        color: var(--textSd);

        & td {
          padding: 0.25rem /* 4px */;
        }
      }
    }
  }

  & pre {
    background: var(--surfaceBg);
    background-size: cover;
    color: var(--textSd);
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    padding: 0.75rem /* 12px */;
    border-radius: 0.375rem /* 6px */;
    overflow: hidden;
    overflow-x: scroll;
    
    & span {
      color: var(--blueLight);
    }
    & .lv {
      color: var(--contrastBlue);
    }
    & .rv {
      color: var(--contrastBlueCode);
    }
    & .ct {
      color: var(--commentDark);
      font-weight: 100;
    }
  }

  .explanation {
    color: var(--textSd);
    display: block;
    padding-bottom: 1.25rem/* 20px */;

    & span {
      color var(--blueLight);
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 600;
    }
  }

  .explanation.last {
    padding-bottom: 0;
  }

  & details {
    & summary {
      color: var(--text);
      cursor: pointer;
      padding-top: 0.75rem/* 12px */;
      padding-bottom: 0.75rem/* 12px */;
    }

    & .subcode {
      color: var(--text);
    }
  }
`

export const DocSection = ({ link, children }) => (
  <DocSectionStyle className="p-5 rounded-md mb-5" id={link}>{children}</DocSectionStyle>
)

import React from "react"
import styled from "styled-components"



const TableStyled = styled.table`
  background: var(--surfaceBg);
  background-size: cover;
  & thead {
    & tr {
      background: var(--blue);
      & th {
        color: var(--blueText);
      }
    }
  }
  & tbody {
    & tr {
      color: var(--textSd);
    }
  }
`

export const Table = ({ children }) => {
  return <TableStyled className="w-full rounded-md">{children}</TableStyled>
}

const CodeSectionStyled = styled.pre`
  background: var(--surfaceBg);
  background-size: cover;
  color: var(--textSd);
  & .highlight {
    color: var(--blueLight);
  }
  & .lv {
    color: var(--contrastBlue);
  }
  & .rv {
    color: var(--contrastBlueCode);
  }
`

export const CodeSection = ({ children }) => (
  <CodeSectionStyled className="text-sm p-3 rounded-md overflow-hidden overflow-x-scroll">
    {children}
  </CodeSectionStyled>
)

const H3Styled = styled.h3`
  color: var(--text);
`

export const H3 = ({ id, children }) => (
  <H3Styled
    className="font-medium text-xl pb-5 text-center md:text-left"
    id={id}
  >
    {children}
  </H3Styled>
)

const H4Styled = styled.h4`
  color: var(--text);
`

export const H4 = ({ id, children }) => (
  <H4Styled className="font-medium text-lg pb-5" id={id}>
    {children}
  </H4Styled>
)

const H6Styled = styled.h6`
  color: var(--text);
`

export const H6 = ({ id, children }) => (
  <H6Styled className="text-sm font-medium pb-2 pt-5" id={id}>
    {children}
  </H6Styled>
)

import React from "react"
import styled from "styled-components"

const MenuList = ({ last, children }) => (
  <MenuListStyle className={`text-sm rounded-md ${last ? "mb-0" : "mb-5"}`}>
    {children}
  </MenuListStyle>
)
const MenuListStyle = styled.ul`
  background: var(--surfaceBg);
  background-size: cover;
`
const MenuListItem = ({ last, title, to, children }) => (
  <MenuListItemStyle
    className={`p-2 ${title ? "rounded-tl-md rounded-tr-md pt-1 pb-1" : ""} ${
      last ? "rounded-bl-md rounded-br-md" : ""
    }`}
    last={last}
    title={title}
  >
    <MenuListLinkStyle
      className={`${title ? "uppercase font-semibold text-xs" : ""}`}
      last={last}
      title={title}
      href={`${to}`}
    >
      {children}
    </MenuListLinkStyle>
  </MenuListItemStyle>
)
const MenuListItemStyle = styled.li`
  background: ${props => (props.title ? "var(--blue);" : "none;")}
  border-bottom: ${props =>
    props.title || props.last ? "none;" : "1px solid var(--surfaceBorder);"}
`
const MenuListLinkStyle = styled.a`
  color: ${props => (props.title ? "var(--blueText);" : "var(--textSd);")};
`

const Menu = ({ page, props }) => (
  <>
    <MenuList>
      <MenuListItem title to={page === "docs" ? "#started" : "/docs#started"}>
        Getting started
      </MenuListItem>
      <MenuListItem to={page === "docs" ? "#definition" : "/docs#definition"}>
        Definitions
      </MenuListItem>
      <MenuListItem to={page === "docs" ? "#api-base" : "/docs#api-base"}>
        API Base URL
      </MenuListItem>
      <MenuListItem to={page === "docs" ? "#api-login" : "/docs#api-login"}>
        API Login Name
      </MenuListItem>
      <MenuListItem
        to={page === "docs" ? "#api-clientId" : "/docs#api-clientId"}
      >
        API Client ID
      </MenuListItem>
      <MenuListItem to={page === "docs" ? "#api-key" : "/docs#api-key"}>
        API Key
      </MenuListItem>
      <MenuListItem
        to={page === "docs" ? "#api-response" : "/docs#api-response"}
      >
        API Response
      </MenuListItem>
      <MenuListItem
        to={page === "docs" ? "#api-endpoints" : "/docs#api-endpoints"}
      >
        Available Endpoints
      </MenuListItem>
      <MenuListItem
        to={page === "docs" ? "#api-errors" : "/docs#api-errors"}
        last
      >
        Potential Errors
      </MenuListItem>
    </MenuList>
    <MenuList last>
      <MenuListItem
        to={page === "docs" ? "/endpoints#endpoints" : "#endpoints"}
        title
      >
        Endpoints
      </MenuListItem>
      <MenuListItem
        to={
          page === "docs"
            ? "/endpoints#api-endpoint-latest-measurement"
            : "#api-endpoint-latest-measurement"
        }
      >
        Latest Measurement
      </MenuListItem>
      <MenuListItem
        last
        to={
          page === "docs"
            ? "/endpoints#api-endpoint-latest-error"
            : "#api-endpoint-latest-error"
        }
      >
        Latest Error Messages
      </MenuListItem>
      <MenuListItem
        last
        to={
          page === "docs"
            ? "/endpoints#api-endpoint-control"
            : "#api-endpoint-control"
        }
      >
        Control Device
      </MenuListItem>
    </MenuList>
  </>
)

export default Menu
